import React, { useState, useEffect } from "react";
import PrismicRichText from "../PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isEmpty, get } from "lodash";
import Link from "../Link";

const Hero = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));
  const [heroLoaded, setHeroLoaded] = useState(false);

  const bgImage = isMobile
    ? props.mobileBackgroundImage
    : props.backgroundImage;

  const backgroundImage = {
    backgroundImage: "url(" + bgImage + ")",
  };

  const backgroundColor = {
    background: props.backgroundColor,
  };

  const ctaAlignment = props.ctaAlignment;
  const sectionAlignment = props.sectionAlignment;

  const heroType = props.type || null;

  useEffect(() => {
    setHeroLoaded(false);
    if (bgImage === undefined) {
      setHeroLoaded(true);
    } else {
      const imageLoader = new Image();
      imageLoader.src = bgImage;
      imageLoader.onload = () => {
        setHeroLoaded(true);
      };
    }
  }, [isMobile, bgImage]);

  const renderButtons = (buttons) => {
    return buttons.map((el, i) => (
      <Link
        key={i}
        url={get(el, "cta_link[0].text")}
        className="button outlined"
      >
        {get(el, "cta_text[0].text") || get(el, "cta_text")}
      </Link>
    ));
  };

  if (!heroLoaded) {
    return (
      <div style={{ position: "relative", width: "100%", height: "70vh" }}>
        <CircularProgress
          style={{
            position: "absolute",
            color: "white",
            top: "calc(50% - 25px)",
            left: "calc(50% - 25px)",
            width: "50px",
            height: "50px",
            zIndex: "50",
            visibility: "visible",
          }}
        />
      </div>
    );
  }

  return (
    <section
      className={`hero-section ${
        sectionAlignment === "Image / Content" && "right-aligned"
      } ${props.extraClasses && props.extraClasses} ${heroType && heroType}`}
    >
      <div className="extra-margin"></div>
      <div className="wrapper">
        <div className="text-content">
          <PrismicRichText render={props.richContent} />
          {!isEmpty(get(props, "buttons[0].cta_link")) && (
            <div
              className={`buttons ${
                ctaAlignment === "Center" && "center-align"
              } ${ctaAlignment === "Right" && "right-align"}`}
            >
              {renderButtons(props.buttons)}
            </div>
          )}
        </div>
      </div>
      <div className="overlay"></div>
      <div className="background-image" style={backgroundImage}></div>
      <div className="background-color" style={backgroundColor}></div>
    </section>
  );
};

export default Hero;
